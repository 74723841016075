
import facebook from './assets/New folder/facebook.png';
import instagram from './assets/New folder/instagram.png';
import React, { useState, useEffect } from "react";
import './Home.css'
import youtube from './assets/New folder/youtube.png';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export const Home = () => {

    const [showAnnouncement, setShowAnnouncement] = useState(false);
    const [, setShowOverlay] = useState(false);
  
    useEffect(() => {
      // Wait 1 second before showing the announcement and overlay
      const timer = setTimeout(() => {
        setShowAnnouncement(true);
        setShowOverlay(true);
      }, 1000);
  
      // Clear the timer if the component is unmounted
      return () => clearTimeout(timer);
    }, []);
  
    const closeAnnouncement = () => {
      setShowAnnouncement(false);
      setShowOverlay(false);
    }


  
  
  return (
    <div className="body">
     
      <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | A Nature Lodge in Skardu</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range" 
        />
        <meta name="robots" content="index, follow" />


        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge,Skardu,skardu, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="A Nature Lodge in Skardu" />
        <meta 
          property="og:description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range in the North of Pakistan" 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Lodge in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
     
      </Helmet>
      <div>
      {/* Overlay to dim the background */}
      {showAnnouncement && (
        <div 
          className="overlay" 
          onClick={closeAnnouncement}
        />
      )}

      {/* Announcement Banner */}
      {showAnnouncement && (
        <div className="announcement-banner">
          <h2>Announcement</h2> <p className="welcomep" style={{paddingLeft: '0vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'2.5vh' ,color:'GrayText'}}>
            Rus Olive Lodge Skardu is closing on November 1, 2024. We will be back in March 2025 to welcome blossoms in Skardu. See you in March.</p>
          <button onClick={closeAnnouncement}>Close</button>
        </div>
      )}

   
    </div>
  

    <div className="MainVid">
     
    <img loading="lazy"  src="Homeimg/aerial.webp" class="mainvideo" alt="Aerial view of Rus Olive Lodge Skardu" />

    </div>





    <div className="morning1" style={{ display: 'flex',flexDirection:'row'}}>

      <img loading="lazy" className="garden" src="Homeimg/morninggarden.webp" alt=""  height="800vh"  style={{ width: '300vw' }}/>
    
    <div className="welcomemain" style={{display:'flex',flexDirection:"column"}} >
    <h1 className="welcome" style={{paddingLeft: '5vw',paddingTop:'5vh', fontSize:'8vh'}}>Welcome</h1>
      <p className="welcomep" style={{paddingLeft: '5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
      Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range in the North of Pakistan. 
      Our lodge offers a peaceful retreat with easy access to Katpana Lake and the Katpana Desert,
       making it the perfect place to experience the natural beauty of the region. Whether you're 
       exploring the desert dunes or relaxing in the serene environment, Rus Olive Lodge
       provides the ideal escape. Book your stay today and immerse yourself in the tranquility of Skardu Valley</p>

    <Link to="/check" className="book-now-btn">
    Book Now 
    </Link>
      
        

    </div>
   
    </div>

    <div className="morning2" style={{ display: 'flex',flexDirection:'row' , paddingTop:'0vh'
    }}>
     
    
    <div className="about" style={{display:'flex',flexDirection:"column"}} >
    <h1  className="welcome" style={{paddingLeft: '5vw', fontSize:'8vh'}}>ABOUT US</h1>
    <h className="aboutsub" style={{paddingLeft: '5vw', fontSize:'5vh',fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'}}>Our Story</h>
      <p className="welcomep" style={{paddingLeft: '5vw',paddingRight:'5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
     
Rus Olive Lodge is named for the abundance of Russian olive trees in the area.
 The lodge is surrounded by a variety of beautiful trees. On one side, towering 
 Himalayan Poplar trees offer an incredible view, with their rustling leaves adding a 
 calming sound. The front of the lodge is adorned with fragrant Russian olive trees, 
 their yellowish-white flowers filling the air with sweetness each spring, while their 
 silvery-gray leaves create a stunning display. The back of the lodge is embraced by apricot trees
 , whose delicate blossoms in spring enhance the picturesque landscape

</p>
<h className="aboutsub" style={{paddingLeft: '5vw', fontSize:'5vh',fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal'}}>Where We Are Located</h>
<p className="welcomep" style={{paddingLeft: '5vw',paddingRight:'5vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
Rus Olive Lodge Skardu is dedicated to preserving the natural beauty of the region, offering a harmonious blend of comfort and eco-conscious living. Our boutique lodge is located 
in Sundus, Skardu, just 15-20 minutes from Skardu Airport. The famous Katpana Cold Desert and Katpana Lake
 are within walking distance, while
 popular attractions such as Blink Lake, Shigar, Sarfanga, and Kachura Lake are all just 15-40 minutes away

</p>
    </div>
  <div className="nightimgmain" style={{display:'flex',flexDirection:'column'}}>
  <img loading="lazy" className="nightimg" src="Homeimg/night1 (1).webp" alt=""  height="435vh"  style={{ width: '50vw' }}/>
  <img loading="lazy" className="nightimg" src="Homeimg/night1 (2).webp" alt=""  height="435vh"  style={{ width: '50vw' }}/>
  {/* <p style={{paddingLeft: '270px', fontFamily: 'Lato , sans-Serif', fontWeight: '300', fontStyle: 'normal', fontSize:'10px' ,color:'green'}}> */}
  {/* Rus Olive in the Moonlight</p> */}
  </div>
    </div>
   









   {/* Footer */}
   <div className='footer'>
    <div className='sb_footer section_padding'>
    <div className='sb_footer-links'>
    <div className='sb_footer-links_div'>
    <h4 className='font'> Lets Keep in touch!</h4>
    <a href='/employer'>
        <p> Find us on any of this plaforms, we response 1-2 business days</p>
    </a>

    <div className='socialmedia'>
<a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL"><p className='social'> <img src={facebook} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'> <img src={instagram} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'  > <img src={youtube} alt="" /></p></a>
</div>
    </div>

    <div className='sb_footer-links_div'>
    <h4 className='font'> USEFUL LINK</h4>
    <a href='/'>
        <p> About us</p>
    </a>
    <a href='/employer'>
        <p> Blog</p>
    </a>
   
  
    <Link to="/check">
    <p> Book a Room</p>
    </Link>
 
    </div>

    
    <div className='sb_footer-links_div'>
    <h4 className='font'> OTHER RESOURCES</h4>
  
    <a href='/terms'>
       <Link to="/term" >
         <p>Term and Conditions</p>
    </Link>
    </a>
    <Link to="/term" >
         <p>Privacy & Policy</p>
    </Link>
    
        <Link to="/contact">
        <p> Contact Us</p>
    </Link>
   </div>


 

    
        
    </div>

    

   <hr></hr>
   <div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p> 
        @{new Date().getFullYear()}  Rus Olive Lodge Skardu. All right reserved.        
        </p>
    </div>
   </div>
    </div>


    </div>
    </div>
  );
};
