import React from "react";
import facebook from './assets/New folder/facebook.png';
import instagram from './assets/New folder/instagram.png';
import './Home.css'
import youtube from './assets/New folder/youtube.png';
import { Link, NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';


export const Restaurant = () => {
  return (
    <div className="body">
   <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Lodge in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range" 
        />
        <meta name="robots" content="index, follow" />


        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | A Nature Lodge in Skardu" />
        <meta 
          property="og:description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range" 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Lodge in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      
      </Helmet>
      <div style={{display: 'flex', width: '100vw', justifyContent: 'center', alignItems: 'center'}}>
  <img  className="hotelLogo" style={{height: '40vh'}} src="favicon1.png" alt=""/>
</div >
     <div className="morning1" style={{ display: 'flex',flexDirection:'row'}}>
      <img loading="lazy" className="gardenimg" src="Homeimg/apricotinfusion.webp" alt=""   style={{ height:"70vh" , width: '150vw' }}/>
    
    <div className="welcomemain" style={{display:'flex',flexDirection:"column"}} >
    <h1 className="restaurantH" style={{paddingLeft: '3vw',paddingTop: '5vh', fontSize:'6vh'}}>Skardu’s Special: Refreshing Apricot Infusion</h1>
      <p className="welcomep" style={{paddingLeft: '3vw', fontFamily: 'Lato , sans-Serif', fontWeight: '500', fontStyle: 'normal', fontSize:'3.5vh' ,color:'GrayText'}}>
      Our apricot infusion is made by gently boiling dried apricots at a high temperature 
      to extract their natural flavors. The water is carefully collected and cooled, 
      resulting in a refreshing, naturally sweet drink that’s perfect for any time of day</p>
      <a href="/check" className="book-now-btn">
          Book Now
        </a>
    </div>
  
    </div>
   




    {/* Footer */}
    <div className='footer'>
    <div className='sb_footer section_padding'>
    <div className='sb_footer-links'>
    <div className='sb_footer-links_div'>
    <h4 className='font'> Lets Keep in touch!</h4>
    <a href='/employer'>
        <p> Find us on any of this plaforms, we response 1-2 business days</p>
    </a>

    <div className='socialmedia'>
<a href="https://www.facebook.com/profile.php?id=61562902921721&mibextid=ZbWKwL"><p className='social'> <img src={facebook} alt="" /></p></a>
<a href="https://www.instagram.com/rusolivelodge?igsh=MWJnNncwbW92Y2FsbA=="><p className='social'> <img src={instagram} alt="" /></p></a>
<a href=""><p className='social'  > <img src={youtube} alt="" /></p></a>
</div>
    </div>

    <div className='sb_footer-links_div'>
    <h4 className='font'> USEFUL LINK</h4>
    <a href='/'>
        <p> About us</p>
    </a>
    <a href='/employer'>
        <p> Blog</p>
    </a>
   
  
    <Link to="/check">
    <p> Book a Room</p>
    </Link>
 
    </div>

    
    <div className='sb_footer-links_div'>
    <h4 className='font'> OTHER RESOURCES</h4>
  
    <a href='/terms'>
       <Link to="/term" >
         <p>Term and Conditions</p>
    </Link>
    </a>
    <Link to="/term" >
         <p>Privacy & Policy</p>
    </Link>
    
        <Link to="/contact">
        <p> Contact Us</p>
    </Link>
   </div>


 

    
        
    </div>

    

   <hr></hr>
   <div className='sb_footer-below'>
    <div className='sb_footer-copyright'>
        <p> 
        @{new Date().getFullYear()}  Rus Olive Lodge Skardu. All right reserved.        
        </p>
    </div>
   </div>
    </div>


    </div>
    </div>
  );
};
