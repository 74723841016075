import React, { useState } from 'react';
import './Roomcheck.css';
import { Helmet } from 'react-helmet';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short', year: 'numeric' };
  return date.toLocaleDateString('en-GB', options); // 'en-GB' to get month in short form
};

export const Roomcheck = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [roomType, setRoomType] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');
  const [totalGuests, setTotalGuests] = useState(1); // Defaulting to 1 guest

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Format dates before submitting
    const formattedCheckInDate = formatDate(checkInDate);
    const formattedCheckOutDate = formatDate(checkOutDate);
  
    // Compose the email body with HTML formatting
    const emailBody = `
  Dear Rus Olive Lodge Team,   <br>
  I hope you’re doing well. I’m interested in booking a room at your hotel for the following dates:
   <br> 
  - Check-in: ${formattedCheckInDate}  + '<br>' 
  - Check-out: ${formattedCheckOutDate}  + '<br>' 
  - Number of guests: ${totalGuests} + '<br>' 
  - Preferred room type: ${roomType} + '<br>' 
  Could you please confirm availability, pricing, and any relevant policies?
  ${specialRequest ? specialRequest + '<br>': ''}
  Thank you in advance for your assistance.  <br>
  Best regards,
  ${name} + <br>
  ${phone} + <br>
  ${email} + <br>
    `;
  
    // Compose the mailto link with the subject and body
    const mailtoLink = `mailto:rusolivelodge@gmail.com?subject=Room Availability Inquiry&body=${encodeURIComponent(emailBody)}`;
  
    // Redirect the user to their email client with pre-filled details
    window.location.href = mailtoLink;
  };
  
 
  return (
    <div className="body">
         <Helmet>
        {/* Title of the page */}
        <title>Rus Olive Lodge | Best Lodge in Skardu - Cozy, Comfortable & Affordable</title>

        {/* Meta description */}
        <meta 
          name="description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range" 
        />
        <meta name="robots" content="index, follow" />


        {/* Meta keywords */}
        <meta 
          name="keywords" 
          content="Rus Olive Lodge, Skardu hotels, affordable hotels Skardu, cozy lodge Skardu, best hotels in Skardu, places to stay in Skardu, Skardu tourism, hotel near UOB Sundus Campus Skardu, vacation Skardu, hotel in Skardu for tourists" 
        />
        
        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Rus Olive Lodge | A Nature Lodge in Skardu" />
        <meta 
          property="og:description" 
          content="Rus Olive Lodge is a small lodge situated in the beautiful Skardu Valley, 
      deep in the mountains of the Karakoram range" 
        />
        <meta 
          property="og:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />
        <meta property="og:url" content="https://rusolivelodge.com" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content="Rus Olive Lodge | Cozy & Affordable Lodge in Skardu"
        />
        <meta 
          name="twitter:description" 
          content="Book your stay at Rus Olive Lodge, a comfortable hotel offering affordable rooms and great service in the heart of Skardu." 
        />
        <meta 
          name="twitter:image" 
          content="https://www.rusolivelodge.com/images/lodge.jpg" 
        />

        {/* Open Graph locale (optional, good for regional SEO) */}
        <meta property="og:locale" content="en_US" />
        
        {/* Canonical URL - Prevents duplicate content issues */}
        <link rel="canonical" href="https://rusolivelodge.com" />
        <meta name="google-site-verification" content="K_pEM6EH78qy2-mzv9wqmGVp4K31IbaZl1fI9j9LIxI" />
      
      </Helmet>
      <div className="container">
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Room Availability Inquiry</h3>
            <p className="text">
              Please fill out the form below to inquire about room availability at our hotel.
            </p>
          </div>

          <div className="contact-form">
            <form onSubmit={handleSubmit} autoComplete="off">
              <h3 className="title">Contact Us</h3>

              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  placeholder="Full Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
                <label></label>
                <span>Full Name</span>
              </div>

              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label></label>
                <span>Email Address</span>
              </div>

              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  className="input"
                  placeholder="Phone Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <label></label>
                <span>Phone Number</span>
              </div>

              <div className="input-container">
                <input
                  type="date"
                   className="checkInDate"
                  name="checkInDate"
                 
                  value={checkInDate}
                  onChange={(e) => setCheckInDate(e.target.value)}
                  required
                />
                <label>Check-In Date</label>
                <span>Check-In Date</span>
              </div>

              <div className="input-container">
                <input
                className="checkOutDate"
                  type="date"
                  name="checkOutDate"
                 
                 
                  value={checkOutDate}
                  onChange={(e) => setCheckOutDate(e.target.value)}
                  required
                />
                <label>Check-Out Date</label>
                <span>Check-Out Date</span>
              </div>

              {/* Room Type Dropdown */}
              <div className="input-container">
                <select
                  name="roomType"
                  className="input select"
                  value={roomType}
                  onChange={(e) => setRoomType(e.target.value)}
                  required
                >
                  <option value="">Select Room Type</option>
                  <option value="Single">Single</option>
                  <option value="Double">Double</option>
                  <option value="Triple">Triple Room</option>
                  <option value="Family">Family Room</option>
                </select>
                <label>Room Type</label>
              </div>

              <div className="input-container">
                <input
                  type="number"
                  name="totalGuests"
                  className="input"
                  value={totalGuests}
                  onChange={(e) => setTotalGuests(e.target.value)}
                  required
                  min="1"
                  placeholder="Total Guests"
                />
                <label></label>
                <span>Total Guests</span>
              </div>

              <div className="input-container textarea">
                <textarea
                  name="specialRequest"
                  className="input"
                  value={specialRequest}
                  onChange={(e) => setSpecialRequest(e.target.value)}
                  placeholder="Any special requests (optional)"
                />
                <label></label>
                <span>Special Requests</span>
              </div>

              <input type="submit" value="Submit Inquiry" className="btn" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
